import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import 'jquery/dist/jquery.js'
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content';
import 'es6-symbol/implement'
import {
  Routes,
  Route
} from "react-router-dom";
import About from './components/About';
import Network from './components/Network';
import pages from './data/pages.json'
import React from 'react';
import Service from './components/Service';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import QuickQuoteModal from './components/QuickQuoteModal';
import PageNotFound from './PageNotFound';


function App() {

const [services, setservices] = React.useState([])
const [modal, setModal] = React.useState(false)
const showMadal = () =>{
  setModal(true);
}

const hideModal = () => {
setModal(false);
}

React.useEffect(() => {
  setservices(pages)
}, [])



  return (
    <>
    <Header showModal={showMadal}/>
    <Routes>
      <Route exact path='/' element={<Content showModal={showMadal} title={"DHL Car Transport"}/>}></Route>
      <Route exact path="/about_us" element={<About showModal={showMadal} title={"About Us - DHL Car Transport"}/>}></Route>
      <Route exact path='/network' element={<Network title={"Our Network - DHL Car Transport"} showModal={showMadal}/>}></Route>
      {
      services.map((service) => {
       return <Route exact path={service.url} element={<Service page={service.page} page_title={service.page_title} page_content={service.page_content} showModal={showMadal} title={`${service.page_title} - DHL Car Transport`}/>}></Route>
      })
    }
    <Route exact path='/gallery' element={<Gallery title={"Gallery - DHL Car Transport"}/>}></Route>
    <Route exact path='/contact_us' element={<Contact title={"Contact Us - DHL Car Transport"}/>}></Route>
    <Route path="*" element={<PageNotFound />} />
    </Routes>
    <QuickQuoteModal show={modal} hideModal={hideModal}/>
    <Footer showModal={showMadal}/>
  
    </>
  );
}

export default App;
