export default function validateForm(values){
let errors = {}

    if(!values.name.trim()){
        errors.name = "Please Enter Your Name!"
        errors.icon_name = "fa-circle-xmark text-danger"
        errors.hasName = false
    }else if(values.name.length >50){
        errors.name = "Name Is Too Long!"
        errors.icon_name = "fa-circle-xmark text-danger"
        errors.hasName = false
    }else{
        errors.name = "Valid Input!"
        errors.icon_name = "fa-circle-check text-success"
        errors.hasName = true;
    }

    if(!values.email.trim()){
        errors.email = "Please Enter Your Email!"
        errors.icon_email = "fa-circle-xmark text-danger"
        errors.hasEmail = false
    }else if( !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email))){
        errors.email = "Please Enter A Valid Email!"
        errors.icon_email = "fa-circle-xmark text-danger"
        errors.hasEmail = false
    }else{
        errors.email = "Valid Input!"
        errors.icon_email = "fa-circle-check text-success"
        errors.hasEmail = true
    }

    if(!values.phone.trim()){
        errors.phone = "Please Enter Your Phone Number!"
        errors.icon_phone = "fa-circle-xmark text-danger"
        errors.hasPhone = false

    }else if(!(/^\d{10}$/.test(values.phone))){
        errors.phone = "Please Enter A Valid Phone Number."
        errors.icon_phone = "fa-circle-xmark text-danger"
        errors.hasPhone = false
    }else{
        errors.phone = "Valid Input!"
        errors.icon_phone = "fa-circle-check text-success"
        errors.hasPhone = true
    }

    if(!values.location_from.trim()){
        errors.location_from = "Please Enter A Valid From Location!"
        errors.icon_locationFrom = "fa-circle-xmark text-danger"
        errors.hasLocationFrom = false
    }else{
        errors.location_from = "Valid Input!"
        errors.icon_locationFrom = "fa-circle-check text-success"
        errors.hasLocationFrom = true
    }


    if(!values.location_to.trim()){
        errors.location_to = "Please Enter A Valid To Location!"
        errors.icon_locationTo = "fa-circle-xmark text-danger"
        errors.hasLocationTo = false
    }else{
        errors.location_to = "Valid Input!"
        errors.icon_locationTo = "fa-circle-check text-success"
        errors.hasLocationTo = true
    }
    
return errors;

}