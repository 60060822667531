import React from "react";
import HeroPages from "./HeroPages";
import Cta2 from "./Cta2";
import QuickQuote from "./QuickQuote";

function Service(props) {
  document.title = props.title;

  return (
    <>
      <HeroPages page_title={props.page_title} page={props.page} />
      <section className="network" id="section_service">
        <div className="container">
          <div className="section-title pb-0 text-center">
            <h2>{props.page_title}</h2>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
                <p className="page-content">{props.page_content}</p>
            </div>
            <div className="col-md-4 col-sm-12">
                <QuickQuote form_hero={false}/>
                </div>
          </div>
        </div>
      </section>
      <Cta2 link_to_form="#section_service" showModal={props.showModal}/>
    </>
  );
}

export default Service;
