import React from "react";
import logo_sticky from "../img/logo_sticky.png";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <>
      <footer className="bd-footer">
        <div className="container mt-0 p-5 mb-0 border-top bg-body">
          <div className="row d-flex justify-contents-center container m-0">
            <div className="col-md-5">
              <img src={logo_sticky} alt="" width="220px" className="footer_logo" />
              <p className="text-justify">
                DHL Car Transport is the perfect destination for professional shifting, moving and relocation for residential and commercial clients in India.
              </p>
            </div>
            <div className="col-md-2 text-start">
              <h5>Menu</h5>
              <ul className="list-unstyled text-small mb-0 mt-4">
                <li className="mb-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="mb-2">
                  <Link to="/about_us">About Us</Link>
                </li>
                <li className="mb-2">
                  <Link to="/network">Network</Link>
                </li>
                <li className="mb-2">
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 text-start">
              <h5>Services</h5>
              <ul className="list-unstyled text-small mb-0 mt-4">
                <li className="mb-2">
                  <Link to="/car_transport">Car Transport</Link>
                </li>
                <li className="mb-2">
                  <Link to="/bike_transport">Bike Transport</Link>
                </li>
                <li className="mb-2">
                  <Link to="/office_shifting">Office Shifting</Link>
                </li>
                <li className="mb-2">
                  <Link to="/house_relocation">House Relocation</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 text-start">
              <h5>Contact Us</h5>
              <ul className="list-unstyled text-small mb-0 mt-4">
                <li className="mb-2">
                  <i className="fa-solid fa-location-dot me-2" />
                  RN-2, Punwasi Yadav Chawl, Daruwala Compound, S. V. Road, Malad West, Mumbai MH 400064
                </li>
                <li className="mb-2">
                  <a href="tel:+917506667070">
                    <i className="fa-solid fa-phone me-2" />
                    +91 7506667070
                  </a>
                </li>
                <li className="mb-2">
                  <a href="mailto:info@dhlcartransport.com">
                    <i className="fa-solid fa-envelope me-2" /> info@dhlcartransport.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-black text-center text-light py-3 footer-credit">
          <p className="p-0 m-0">
            Copyright &#169; 2022 <Link to="/">DHL Car Transport</Link>. All Rights Reserved
          </p>
        </div>
      </footer>
      <div className="position-fixed mobile-cta">
        <div className="mobile-cta-content">
          <ul className="cta-list d-flex justify-content-center align-items-center">
            <li className="cta-item">
              <a href="tel:+917506667070">
                <i class="fa-solid fa-phone"></i>
              </a>
            </li>
            <li className="cta-item">
              <a href="https://wa.me/+917506667070">
                <i class="fa-brands fa-whatsapp"></i>
              </a>
            </li>
            <li className="cta-item">
              <a href="#" onClick={props.showModal}>
                <i class="fa-solid fa-pen-to-square"></i>
                <span class="cta-item-text">Get Quote</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
