import { useEffect, useState } from "react";


const useForm = (validateForm) => {
  const [values, setValues] = useState({ name: "", email: "", phone: "", location_from: "", location_to: "", page_url: window.location.href});
  const [errors, seterrors] = useState({});
  const [deliveryError, setDeliveryError] = useState({});
  const [noErrors, setNoErrors] = useState(false)
  const [isSending, setisSending] = useState(false);
  const {hasName, hasEmail, hasPhone, hasLocationFrom, hasLocationTo} = errors
  const handleChange = (e) => {
    seterrors(validateForm(values));
    setDeliveryError({})
    setValues({ ...values, [e.target.name]:e.target.value });
    console.log(values.phone.length)
  };


  useEffect(() => {
    if(values.name || values.email || values.phone || values.location_from || values.location_to){seterrors(validateForm(values))}
  }, [values])
  

  const checkErrors = () =>{
    if(errors.name === "Valid Input!" && errors.email === "Valid Input!" && errors.phone === "Valid Input!"
        && errors.location_from === "Valid Input!" && errors.location_to === "Valid Input!"){
          setNoErrors(true)
        }else {
          setNoErrors(false)
        } 

        console.log(noErrors)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDeliveryError({})
   
      if(!hasName || !hasEmail || !hasPhone || !hasLocationFrom || !hasLocationTo){
        setDeliveryError({message:"All the fields are required!", color:"text-danger"})
      }

      console.log(hasName, hasEmail, hasPhone, hasLocationFrom, hasLocationTo)
 
   
    // if(noErrors){
      if(hasName && hasEmail && hasPhone && hasLocationFrom && hasLocationTo)
      { setisSending(true);
      fetch(`${process.env.REACT_APP_MAIL_API}`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "Sent") {
            setValues({name:"",email:"",phone:"",location_from:"",location_to:""})
            setNoErrors(false);
            seterrors({})
            setisSending(false);
            setDeliveryError({message:"Thanks.You'll receive quote soon.", color:"text-success", sent:true}) 
            window.dataLayer.push({
              event: "request_quote"
            });
          } else if (response.status === "Failed") {
            alert("Message failed to send.");
          }
        }).catch((error) =>{
          if(error.response){
            errors.delivery_error = error.response.data
          }
          else{
            setisSending(false);
            setDeliveryError({message:"Something Went Wrong", color:"text-danger", sent:false})
        }} );
        
      }
   
  };

  return { handleChange, values, handleSubmit, errors, deliveryError, isSending };
};

export default useForm;
