import React from 'react'
import error from "./img/404.webp"
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <>
    <div className="container" style={{padding:60}}>
        <div className="d-flex flex-column justify-contents-center align-items-center">
            <img src={error} alt="Error 404 - Page Not Found" style={{paddingTop:120, width:300}}/>
            <h2>Page Not Found</h2>
            <Link to="/" className="theme-btn btn-style-one border-0 text-decoration-none mt-4">
                  <span className="btn-title">Go To Home</span>
                </Link>
        </div>
    </div>
    </>
  )
}

export default PageNotFound