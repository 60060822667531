import React from "react";
import HeroPages from "./HeroPages";
import img1 from "../img/bike-about.webp"
import img2 from "../img/about.webp"
import Cta2 from "./Cta2";

function About(props) {

  document.title = props.title;

  return (
    <>
      <HeroPages page_title="About Us" page="Page"/>
      <section className="about-section">
        <div className="container">
        <div className="row">
          <div className="content-column col-xl-6 col-lg-7 col-md-12 col-sm-12 order-2">
            <div className="inner-column">
              <div className="sec-title">
                <span className="sub-title">About Us</span>
                <h2>We Provide Best Car Transportation Service in Mumbai and Accross Country</h2>
                <div className="text">
                DHL Car Transport, was started with a vision to make vehicle relocation easy and affordable. We are offering Vehicle Moving,Transportation Services, Office Relocation, Car Transportation and Warehousing Services etc.
                </div>
              </div>

              <ul className="list-style-two">
                <li>
                  <i className="fa fa-check-circle"></i> Quality Assured Services.
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Safe & On-Time Delivery Guaranteed.
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Affordable & Competitive Rates.
                </li>
              </ul>

              <div className="btn-box">
                <a href="tel:+917506667070" className="info-btn">
                  <i className="icon fa fa-phone"></i>
                  <small>Call Anytime</small> +917506667070
                </a>
                <button onClick={props.showModal} className="theme-btn btn-style-one border-0">
                  <span className="btn-title">Request Quote Now</span>
                </button>
              </div>
            </div>
          </div>

          <div className="image-column col-xl-6 col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column wow fadeInLeft animated">
              <figure className="image-1 overlay-anim wow fadeInUp animated">
                <img src={img1} alt="" />
              </figure>
              <figure className="image-2 overlay-anim wow fadeInRight animated">
                <img src={img2} alt="" />
              </figure>
            </div>
          </div>
        </div>
        </div>
      
      </section>

      <section id="services" className="services">
      
            <div className="container">
              <div className="section-title">
                <h2>Services</h2>
                <p>Our Professional Transportation Service Provide Safe, Secure & Door To Door Vehicle Relocation in Every Corner of The Country With Ease.</p>
              </div>
              <div className="row row-services">
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-car fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Car Transportation</h4>
                    <p>We provide the best Car Transportation service with option of single and multiple car carriers.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-motorcycle fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Bike Transportation</h4>
                    <p>We provide best and safe bike transportation services with cost effective prices.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-house fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Home Relocation</h4>
                    <p>We care for you and therefore our professional workers safely shifts your household items.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-building fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Office Relocation</h4>
                    <p>We hold expertise in safely shifting official goods with very cost effective rates and quality packing.</p>
                  </div>
                </div>
              </div>
          </div>
        </section>
      <Cta2 showModal={props.showModal}/>

    </>
  );
}

export default About;
