import React from "react";
import Cta from "./Cta";
import Hero from "./Hero";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import Cta2 from "./Cta2";
function Content(props) {
  const [counter, setcounter] = React.useState(false);
    document.title = props.title;

  return (
    <>
      <Hero />
      <Cta />
      <div className="container-fluid p-0">
        <section id="services" className="services">
          <div className="container">
            <div className="row p-0 m-0 position-relative">
              <div className="col-sm-12 col-md-12 col-lg-7 p-0">
                <div className="section-title pb-0  ">
                  <h2>Welcome To DHL Car Transport</h2>
                </div>
                <div className="container">
                  <p style={{ "text-align": "justify" }} className="p-4 pt-1 fs-5">
                    <b>DHL Car Transport</b>, was started with a vision to make vehicle relocation easy and affordable. We are offering <b>Vehicle Moving</b>,
                    <b>Transportation Services</b>, <b>Office Relocation</b>, <b>Car Transportation</b> and Warehousing Services etc. DHL Car Transport is the
                    perfect destination for professional shifting, moving and relocation services to residential and commercial clients in India. We understand
                    the stress of clients while moving and our company goal is to make it easy. <p />
                    We are a leading and experienced <b>Car Movers</b> company that is diligent and committed to providing rapid, authentic, and effective
                    services. Our Company provide proper and on-time pickup and delivery services. We have the experienced professional team which is highly
                    trained and certified.
                  </p>
                </div>
              </div>
              <div className="d-none col-lg-5 text-center d-lg-flex justify-content-center align-items-stretch">
                <div className="row align-items-center">
                  <div className="col-sm col-md-12">
                    <div className="w-50 p-4 shadow float-end custom-box">
                      <div className="icon-box">
                        <div className="icon pb-3">
                          <i className="fa-solid fa-people-group color-primary fs-40"></i>
                        </div>
                        <h4 className="text-uppercase box-title">Expert Staff</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm col-md-12">
                    <div className="w-50 p-4 shadow custom-box-fixed">
                      <div className="icon-box">
                        <div className="icon pb-3 ">
                          <i className="fa-solid fa-award fs-40 color-primary"></i>
                        </div>
                        <h4 className="text-uppercase box-title">Quality Shifting</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm col-md-12">
                    <div className="w-50 p-4 shadow float-end custom-box">
                      <div className="icon-box">
                        <div className="icon pb-3 ">
                          <i className="fa-solid fa-indian-rupee-sign fs-40 color-primary"></i>
                        </div>
                        <h4 className="text-uppercase box-title">Affordable Rates</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section-bg shadow">
          <div className="bg-overlay">
            <div className="container">
              <div className="section-title">
                <h2>Services</h2>
                <p>Our Professional Transportation Service Provide Safe, Secure & Door To Door Vehicle Relocation in Every Corner of The Country With Ease.</p>
              </div>
              <div className="row row-services">
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-car fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Car Transportation</h4>
                    <p>We provide the best Car Transportation service with option of single and multiple car carriers.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-motorcycle fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Bike Transportation</h4>
                    <p>We provide best and safe bike transportation services with cost effective prices.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-house fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Home Relocation</h4>
                    <p>We care for you and therefore our professional workers safely shifts your household items.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 d-flex align-items-stretch p-4 service-item border-right">
                  <div className="icon-box">
                    <div className="icon pb-3 ">
                      <span className="fa-stack fa-2x">
                        <i className="fas fa-circle fa-stack-2x icon-bg"></i>
                        <i className="fa-solid fa-building fa-stack-1x fa-inverse icon-color"></i>
                      </span>
                    </div>
                    <h4 className="text-uppercase">Office Relocation</h4>
                    <p>We hold expertise in safely shifting official goods with very cost effective rates and quality packing.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Waypoint onEnter={() => setcounter(true)} onLeave={() => setcounter(false)}>
          <div className="container bg-light shadow" id="counter">
            <div className="row d-flex justify-content-center align-items-center text-center p-4 shadow">
              <div className="col-sm-6 col-md-3 p-2">
                <div className="counts">{counter && <CountUp start={0} end={3} />}+</div>
                <div className="counts-desc">Years of Experience</div>
              </div>
              <div className="col-sm-6 col-md-3 p-2">
                <div className="counts">{counter && <CountUp start={0} end={1} />}+</div>
                <div className="counts-desc">Thousand of Satisfied Customers</div>
              </div>
              <div className="col-sm-6 col-md-3 p-2">
                <div className="counts">{counter && <CountUp start={0} end={90} />}+</div>
                <div className="counts-desc">Percentage of cities covered</div>
              </div>
              <div className="col-sm-6 col-md-3 p-2">
                <div className="counts">
                  {counter && <CountUp start={0} end={24} />}x{counter && <CountUp start={0} end={7} />}
                </div>
                <div className="counts-desc">Customer Support</div>
              </div>
            </div>
          </div>
        </Waypoint>
        <section id="process" className="our-process">
          <div className="container">
            <div className="section-title">
              <h2>Our Process</h2>
            </div>
            <div className="row-process">
              <ul>
                <li>
                  <span>
                    <small className="process-bg">1</small>
                    <a className="fa-regular fa-angles-right"></a>
                    <i className="fa-solid fa-pen-to-square"></i>
                  </span>
                  <h6>Book Your Order</h6>
                </li>
                <li>
                  <span>
                    <small className="process-bg">2</small>
                    <a className="fa-regular fa-angles-right"></a>
                    <i className="fa-solid fa-truck-ramp-box"></i>
                  </span>
                  <h6>Load The Vehicle</h6>
                </li>
                <li>
                  <span>
                    <small className="process-bg">3</small>
                    <a className="fa-regular fa-angles-right"></a>
                    <i className="fa-solid fa-truck-fast"></i>
                  </span>
                  <h6>Move Your Vehicle</h6>
                </li>
                <li>
                  <span>
                    <small className="process-bg">4</small>
                    <a className="fa-solid fa-check"></a>
                    <i className="fa-solid fa-truck"></i>
                  </span>
                  <h6>Delivered</h6>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="why-us section-bg">
          <div className="bg-overlay-dark">
            <div className="container">
              <div className="section-title">
                <h2>Why Choose Us</h2>
                <p>Fastest Transportation Service & Affordable Prices!</p>
              </div>
              <div className="row row-why-us">
                <div className="col-xl-3 col-md-3 p-2 d-flex align-items-stretch">
                  <div className="p-4 why-us-item">
                    <div className="icon-box">
                      <div className="icon pb-3 ">
                        <span className="fa-stack fa-2x">
                          <i className="fas fa-circle fa-stack-2x why-us-icon-bg"></i>
                          <i className="fa-solid fa-award fa-stack-1x fa-inverse why-us-icon-color"></i>
                        </span>
                      </div>
                      <h4 className="text-uppercase">Quality Assured</h4>
                      <p>We provide the highest quality guaranteed services at affordable and cost effective prices.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 p-2 d-flex align-items-stretch">
                  <div className="p-4 why-us-item">
                    <div className="icon-box">
                      <div className="icon pb-3 ">
                        <span className="fa-stack fa-2x">
                          <i className="fas fa-circle fa-stack-2x why-us-icon-bg"></i>
                          <i className="fa-solid fa-wallet fa-stack-1x fa-inverse why-us-icon-color"></i>
                        </span>
                      </div>
                      <h4 className="text-uppercase">Affordable</h4>
                      <p>Get our best quality transportation services at affordable and competitive prices.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 p-2 d-flex align-items-stretch">
                  <div className="p-4 why-us-item">
                    <div className="icon-box">
                      <div className="icon pb-3 ">
                        <span className="fa-stack fa-2x">
                          <i className="fas fa-circle fa-stack-2x why-us-icon-bg"></i>
                          <i className="fa-solid fa-shield-halved fa-stack-1x fa-inverse why-us-icon-color"></i>
                        </span>
                      </div>
                      <h4 className="text-uppercase">Safe Services</h4>
                      <p>We provide safe, secure and hassle free transportation services without any damage.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-3 p-2 d-flex align-items-stretch">
                  <div className="p-4 why-us-item">
                    <div className="icon-box">
                      <div className="icon pb-3 ">
                        <span className="fa-stack fa-2x">
                          <i className="fas fa-circle fa-stack-2x why-us-icon-bg"></i>
                          <i className="fa-regular fa-hourglass-half fa-stack-1x fa-inverse why-us-icon-color"></i>
                        </span>
                      </div>
                      <h4 className="text-uppercase">Timely Delivery</h4>
                      <p>Client's satisfaction is our satisfaction. So we always try to deliver services on time.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Cta2 link_to_form="#hero" showModal={props.showModal}/>
    </>
  );
}

export default Content;
