import React from "react";
import HeroPages from "./HeroPages";
import QuickQuote from "./QuickQuote";

function Contact(props) {
  document.title = props.title;
  return (
    <>
      <HeroPages page_title="Contact Us" page="Contact" />
      <section className="contact-section">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-12">
            <div className="wrapper">
              <div className="row justify-content-center">
                <div className="col-lg-8 mb-5">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center c-address">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-location-dot"></span>
                        </div>
                        <div className="text">
                          <p>
                            <span>Address:</span> RN-2, Punwasi Yadav Chawl, Daruwala Compound, S. V. Road, Malad West, Mumbai MH 400064
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center c-phone">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-phone"></span>
                        </div>
                        <div className="text">
                          <p>
                            <span>Phone:</span> <a href="tel:+917506667070">+91 7506667070</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dbox w-100 text-center c-email">
                        <div className="icon d-flex align-items-center justify-content-center">
                          <span className="fa fa-paper-plane"></span>
                        </div>
                        <div className="text">
                          <p>
                            <span>Email:</span> <a href="mailto:info@dhlcartransport.com">info@dhlcartransport.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="contact-wrap">
                    <QuickQuote form_hero={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
