import React from "react";
import HeroPages from "./HeroPages";
import images from "../data/images.json";

function Gallery(props) {
  document.title = props.title;
  return (
    <>
      <HeroPages page_title="Gallery" page="Gallery" />
      <div className="container gallery">
        <div className="section-title pb-0 text-center">
          <h2>Gallery</h2>
        </div>
        <div className="row pt-4 image-gallery">
          {images.map((image) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 pt-2" id={image.id}>
                <img src={image.image_url} className="rounded" alt="DHL Car Transport - Car Transport Service in India" height={50}/>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Gallery;
