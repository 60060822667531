import React from 'react';
import HeroPages from './HeroPages';
import network from '../img/network.webp'
import Cta2 from './Cta2';

function Network(props){
  document.title = props.title;

    return (
        <>
        <HeroPages page_title="Our Network" page="Network" />
        <section className='network'>
          <div className="container">
          <div className="section-title pb-0 text-center">
                  <h2>Our Network</h2>
                  <img src={network} width="1024" height="1117" alt="Car Transport Service in India" class="img-fluid"/>
                </div></div>   
        </section>
        <Cta2 showModal={props.showModal}/>
        </>
    )
}

export default Network;