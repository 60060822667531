import React from "react";

function Cta2(props) {
  return (
    <div className="container-fluid cta-2 d-block" style={{ background: "#DC3545" }}>
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-md-9 col-sm-12 d-flex-column align-items-center">
          <h4 className="text-light text-uppercase cta-2-h4 fw-bold p-0 m-0">Ready for a Stress-Free Shifting?</h4>
          <p className="p-0 m-0 text-light cta-2-p fw-light">Get the best transportation service according to your needs.</p>
        </div>
        <div className="col-md-3 col-sm-12">
          <a className="float-md-end text-uppercase cta-2-btn" onClick={props.showModal}><span className="btn-title">Get Quote</span></a>
        </div>
      </div>
    </div>
  );
}

export default Cta2;
