import React from "react";
import QuickQuote from "./QuickQuote";

function QuickQuoteModal(props) {
  const showHideClassName = props.show ? "modal d-block" : "modal d-none";
  const hideModal = props.hideModal;
  return (
    <>
      <div class={showHideClassName}>
        <div className="modal-main d-flex justify-content-center align-items-center">
          <QuickQuote is_modal={true} hideModal={hideModal}/>
        </div>
      </div>
    </>
  );
}

export default QuickQuoteModal;
