import React from 'react'

function HeroPages(props) {
  return (
<div className="container-fluid hero-about d-flex align-items-baseline justify-content-around" id='hero-about'>
        <div className="auto-container">
        <div className="title-outer">
				<h1 className="title">{props.page_title}</h1>
				<ul className="page-breadcrumb">
					<li><a href="index.html">Home</a></li>
					<li>{props.page}</li>
				</ul>
			</div>
        </div>
      </div>  )
}

export default HeroPages